exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-prop-trading-js": () => import("./../../../src/pages/blog/prop-trading.js" /* webpackChunkName: "component---src-pages-blog-prop-trading-js" */),
  "component---src-pages-blog-risk-psychology-trade-js": () => import("./../../../src/pages/blog/risk-psychology-trade.js" /* webpackChunkName: "component---src-pages-blog-risk-psychology-trade-js" */),
  "component---src-pages-blog-trader-gambler-js": () => import("./../../../src/pages/blog/trader-gambler.js" /* webpackChunkName: "component---src-pages-blog-trader-gambler-js" */),
  "component---src-pages-blog-trader-without-capital-js": () => import("./../../../src/pages/blog/trader-without-capital.js" /* webpackChunkName: "component---src-pages-blog-trader-without-capital-js" */),
  "component---src-pages-blog-trading-journal-js": () => import("./../../../src/pages/blog/trading-journal.js" /* webpackChunkName: "component---src-pages-blog-trading-journal-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-challenges-js": () => import("./../../../src/pages/challenges.js" /* webpackChunkName: "component---src-pages-challenges-js" */),
  "component---src-pages-commission-js": () => import("./../../../src/pages/commission.js" /* webpackChunkName: "component---src-pages-commission-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-what-is-prop-firm-js": () => import("./../../../src/pages/what-is-prop-firm.js" /* webpackChunkName: "component---src-pages-what-is-prop-firm-js" */),
  "component---src-pages-درخواست-سرمایه-پراپیفای-js": () => import("./../../../src/pages/درخواست_سرمایه_پراپیفای.js" /* webpackChunkName: "component---src-pages-درخواست-سرمایه-پراپیفای-js" */)
}

